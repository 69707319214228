
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AutoCompleter, { AutocompleteProvider } from '@/components/inputs/AutoCompleter.vue'
import ZoneCard from './ZoneCard.vue'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import ZoneAutocompleteResult from './ZoneAutocompleResult'

@Component({
  components: {
    AutoCompleter,
    ZoneCard
  }
})

export default class ZoneEditor extends Vue {
  private loading = false

  // Results
  public searchTerm = ''
  public minTermLength = 3;

  // Selections
  @Prop({ required: true })
  private readonly value!: ZoneModel[];

  @Prop({ default: () => [] })
  private readonly excludedZones!: ZoneModel[];

  @Prop({ default: 1 })
  private readonly maxSelections!: number;

  @Prop({ default: 1 })
  private readonly minSelections!: number;

  private results: ZoneAutocompleteResult[] = []

  // private branchId: string = this.$store.state.identity.BusinessBranch.BusinessBranchId
  // private categories: CategoryModel[] = this.$store.state.identity.BusinessBranch.Categories;

  get isDisabled (): boolean {
    const hasMaxSelections = this.maxSelections >= this.value.length
    return hasMaxSelections
  }

  private zoneProvider: AutocompleteProvider<ZoneAutocompleteResult> = {
    Search: function (term: string): Promise<ZoneAutocompleteResult[]> {
      return (Services.API.Zones.Autocomplete(term)
        .then((returnModels) => {
          return returnModels.map((result) => {
            return {
              Label: result.TMName,
              Value: result.ZoneId,
              Zone: result
            } as ZoneAutocompleteResult
          }) as ZoneAutocompleteResult[]
        })
      )
    },
    Suggest: function (term: string): Promise<string|null> {
      console.log(term)
      throw new Error('Function not implemented.')
    }
  }

  // Selections
  onResultSelected (selection: ZoneAutocompleteResult): void {
    const alreadyInZones = this.value.find((val) => val.ZoneId === selection.Zone.ZoneId)
    if (alreadyInZones !== undefined) {
      return
    }
    this.$emit('selectedCategory', selection.Zone)
  }

  removeZone (zoneId: string): void {
    if (!this.value) return
    if (this.value.length <= this.minSelections) return
    this.$emit('removeCategory', zoneId)
  }
}
