enum BusinessBranchStates {
    Queensland = '3a538c7b-1cbb-4587-8eea-e66fbb7f71f4',
    NewSouthWales = '21c4507e-3fe0-463a-af83-2cf34290ade3',
    Victoria = '661ab8f9-16bf-4b51-b64e-0f50ae1deb7F',
    Tasmania = 'c37D2ec8-7ab4-4cd2-a24c-1442D37820de',
    SouthAustralia = 'cf8ac06f-4021-48c5-bcd8-181781fa51f8',
    WesternAustralia = '0C1ab89e-b800-4913-bf68-fc8cdedadd30',
    NorthernTerritory = '80009baf-20e6-42d3-9930-4620c1924cD7',
    AustralianCapitalTerritory = '5a27e46b-f13c-49d9-a6a8-21f96e87713b'
}

export default BusinessBranchStates
