
import { FormPage } from '@/mixins'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchMainZoneUpdateDto from 'tradingmate-modules/src/models/api/businesses/BusinessBranchMainZoneUpdateDto'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import ZoneEditor from '../zones/ZoneEditor.vue'
import ZoneCard from '@/components/collections/zones/ZoneCard.vue'

@Component({
  components: {
    ZoneCard,
    ZoneEditor
  }
})

export default class MainZoneEditor extends Mixins(FormPage) {
  private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch ?? null
  private mainZone: ZoneModel[] = this.branch?.MainZone ? [this.branch.MainZone] : []
  private additionalZones: ZoneModel[] = this.branch?.Zones ? this.branch.Zones : []

  onSelectedCategory (zone: ZoneModel): void {
    if (!this.branch) return
    if (!this.mainZone) return
    const alreadyInAddtionalZone = this.additionalZones.some((item) => item.ZoneId === zone.ZoneId)
    if (alreadyInAddtionalZone) return
    this.loading = true
    const dto: BusinessBranchMainZoneUpdateDto = { ZoneId: zone.ZoneId }
    Services.API.Businesses.MainZoneUpdate(this.branch.BusinessBranchId, dto)
      .then((result) => {
        this.branch.MainZone = result
        this.mainZone = [result]
      })
      .catch((error) => { this.httpError = error })
      .finally(() => { this.loading = false })
  }

  onRemoveCategory (zoneId: string): void {
    throw new Error(`Function not implemented. ZoneID: ${zoneId}`)
  }
}

