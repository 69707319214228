
import { FormPage } from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import BusinessBranchStates from 'tradingmate-modules/src/models/api/businesses/BusinessBranchStates'

@Component({
  computed: {
    BusinessBranchStates () {
      return BusinessBranchStates
    }
  },
  components: {
  }
})
export default class StateEditor extends Mixins(FormPage) {
  @Prop({ required: true })
  private readonly value!: string

  get hasValue (): boolean {
    return this.value !== ''
  }

  onInput (value: string): void {
    this.httpError = null
    this.$emit('input', value)
  }
}
