
import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class FormCheckboxField extends Vue {
  @Prop({ default: '' })
  private readonly label!: string

  @Prop({ default: null })
  private readonly errors!: string[] | null
}

