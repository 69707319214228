
import { FormPage } from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import PackageCheckout from '@/components/collections/account/PackageCheckout.vue'
import { Services } from 'tradingmate-modules/src/services'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import PackageCheckoutV4 from '@/components/collections/account/PackageCheckoutV4.vue'

@Component({
  components: {
    PackageCheckoutV4,
    PackageCheckout
  }
})
export default class Membership extends Mixins(FormPage) {
  private branch: BusinessBranchModel | null = null

  @Prop({ default: null })
  private propBranch!: BusinessBranchModel | null

  mounted (): void {
    if (this.propBranch)
    {
      this.branch = this.propBranch
    }
    else {
      const id = this.$route.params.id
      if (!id) return
      this.loading = true
      Services.API.Businesses.GetBranch(id)
        .then((result) => {
          this.branch = result
        })
        .catch((errors) => {
          console.log(errors)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
