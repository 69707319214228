
import { FormPage } from '@/mixins'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
// import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import ZoneEditor from '../zones/ZoneEditor.vue'

@Component({
  components: {
    ZoneEditor
  }
})

export default class AdditionalZoneEditor extends Mixins(FormPage) {
  // private branch: BusinessBranchModel = this.$store.state.identity.BusinessBranch ?? null
  // private mainZone: ZoneModel[] = this.branch?.MainZone ? [this.branch.MainZone] : []
  // private additionalZones: ZoneModel[] = this.branch?.Zones ? this.branch.Zones : []

  @Prop({ required: true })
  private readonly value!: ZoneModel[];

  onSelectedCategory (zone: ZoneModel): void {
    console.log(zone)
    // if (!this.branch) return
    // if (!this.mainZone) return
    const alreadyInAddtionalZone = this.value.some((item) => item.ZoneId === zone.ZoneId)
    if (alreadyInAddtionalZone) {
      console.log('already in zone')
      return
    }

    // const isMainZone = this.mainZone.some((item) => item.ZoneId === zone.ZoneId)
    // if (isMainZone) return
    // console.log('zone not added')

    const newValue = [zone, ...this.value]
    console.log(newValue)
    this.$emit('input', newValue)
  }

  onRemoveCategory (zoneId: string): void {
    const index = this.value.findIndex((item) => item.ZoneId === zoneId)
    if (index === -1) return
    const newValue = this.value
    newValue.splice(index, 1)
    this.$emit('input', newValue)
  }
}

