export default class SignupCheckoutRequest {
    SignupPackage: SignupPackage = SignupPackage.B2b
    WantsWebsite = false
    ExpressSignup = false
    MainStateId: string | null = null
    // SelectedState: string | null = null
    AdditionalZoneIds: string[] = []
    Domain = ''
    WantsPhoto = false
}

export enum SignupPackage {
    None = 0,
    B2b = 1,
    Statewide = 2,
    Website = 3,
    PromoPack = 4,
    AllAussieExtras = 5
}