enum BusinessBranchMembershipType
{
    None,
    B2B,
    Statewide,
    Website,
    PromoPack,
    National
}

export default BusinessBranchMembershipType