enum PackageCheckoutQueries
{
  B2b = 'b2b',
  StateWide = 'statewide',
  Website = 'website',
  Promo = 'promo',
  AllAussieExtra = 'aae'
}

export default PackageCheckoutQueries
