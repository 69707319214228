
import { Component, Mixins, PropSync } from 'vue-property-decorator'
import { InputText } from '@/components/inputs'
import FormField from '@/components/forms/FormField.vue'
import { FormPage } from '@/mixins'
import { Services } from 'tradingmate-modules/src/services'

@Component({
  components: {
    FormField, InputText
  }
})

export default class WebsiteDomainChecker extends Mixins(FormPage) {
  @PropSync('valueSync', { required: true })
  private value!: string;

  onInput (): void {
    this.httpError = null
    this.$emit('domainAvailable', false)
  }

  onSearch (): void {
    console.log('start')
    this.loading = true
    console.log(this.loading)
    Services.API.MyWebsites.CheckDomainAvailability(this.value)
      .then((response) => {
        console.log(response)
        this.$emit('domainAvailable', response.Available)
      })
      .catch((error) => {
        console.log(error)
        this.httpError = error
      })
      .finally(() => {
        console.log('finally')
        this.loading = false
      })
  }
}

