
import { FormPage } from '@/mixins'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { InputText } from '@/components/inputs'
import MainZoneEditor from '@/components/collections/business/MainZoneEditor.vue'
import { DropdownListItem } from 'tradingmate-modules/src/forms'
import InputSelect from '@/components/inputs/InputSelect.vue'
import StateEditor from '@/components/collections/zones/StateEditor.vue'
import AdditionalZoneEditor from '@/components/collections/business/AdditionalZoneEditor.vue'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import BusinessBranchStates from 'tradingmate-modules/src/models/api/businesses/BusinessBranchStates'
import {
  BusinessBranchModel,
  BusinessBranchSocialLink,
  SocialLinkType
} from 'tradingmate-modules/src/models/api/businesses'
import { Services } from 'tradingmate-modules/src/services'
import SignupCheckoutRequest, { SignupPackage } from 'tradingmate-modules/src/models/api/signup/SignupCheckoutRequest'
import WebsiteDomainChecker from '@/components/collections/my-website/WebsiteDomainChecker.vue'
import FormCheckboxField from '@/components/forms/FormCheckboxField.vue'
import InputCheckbox from '@/components/inputs/InputCheckbox.vue'
import BusinessBranchMembershipType
  from 'tradingmate-modules/src/models/api/businesses/branches/BusinessBranchMembershipType'

@Component({
  components: {
    InputCheckbox,
    FormCheckboxField,
    WebsiteDomainChecker,
    AdditionalZoneEditor,
    InputSelect,
    InputText,
    MainZoneEditor,
    StateEditor
  }
})
export default class PackageCheckout extends Mixins(FormPage) {
  // Website Stuff
  private hasWebsiteLink = false
  private websiteDomainValid = false
  private websiteDomain = ''

  private localSelected = false
  private statewideSelected = false
  private websiteSelected = false
  private promoPackSelected = false
  private allAussieExtrasSelected = false
  private nationalSelected = false
  private additionalStates: DropdownListItem[] = []
  private additionalZones: ZoneModel[] = []
  private mainStateId = ''

  // Pre-filled stuff
  private wantsExpress = false // passed in query
  private expressSelected = false // checkout is ticked

  private statePrices = new Map<string, number>([
    [BusinessBranchStates.AustralianCapitalTerritory, 22],
    [BusinessBranchStates.NorthernTerritory, 44],
    [BusinessBranchStates.Tasmania, 88],
    [BusinessBranchStates.SouthAustralia, 154],
    [BusinessBranchStates.Queensland, 175],
    [BusinessBranchStates.NewSouthWales, 175],
    [BusinessBranchStates.Victoria, 175],
    [BusinessBranchStates.WesternAustralia, 175]
  ])

  @Prop({ required: true })
  private readonly business!: BusinessBranchModel

  mounted (): void {
    const socialLinks: BusinessBranchSocialLink[] = this.business.SocialLinks
    const websiteLink = socialLinks.some(x => x.LinkType === SocialLinkType.Website)
    if (websiteLink) {
      this.hasWebsiteLink = true
    }

    this.wantsExpress = !!this.$route.query.express
    this.expressSelected = this.wantsExpress

    this.mainStateId = this.business.StateId
  }

  get subscriptionPrice (): number {
    return 99
  }

  get websitePrice (): number {
    // if (this.promoPackSelected) return 0
    // if (this.nationalSelected) return 0
    if (this.websiteSelected) {
      const priceMainState = this.statePrices.get(this.mainStateId)
      if (!priceMainState) return 451
      else {
        return 451 - priceMainState
      }
    }
    return 0
  }

  get regionPricing (): number {
    if (this.nationalSelected) return 1331
    let runningTotal = 0
    runningTotal += (this.additionalZones.length * 22)
    if (this.statewideSelected)
    {
      if (this.mainStateId)
      {
        const priceMainState = this.statePrices.get(this.mainStateId)
        if (priceMainState) runningTotal += priceMainState
      }
    }
    for (const item of this.additionalStates)
    {
      if (!item.Value) continue
      const priceMainState = this.statePrices.get(item.Value.toString())
      if (priceMainState) runningTotal += priceMainState
    }
    return runningTotal
  }

  get promoPackPrice (): number {
    if (this.promoPackSelected) {
      // if (!this.mainStateId) return 'Select State'
      const priceMainState = this.statePrices.get(this.mainStateId)
      if (priceMainState) return 1320 - 99 - priceMainState
    }
    return 0
  }

  get allAussieExtrasPrice (): number {
    if (this.nationalSelected) return 880
    return 0
  }

  get expressPricing (): number {
    if (this.expressSelected) return 33
    return 0
  }

  get isRenewing (): boolean {
    if (this.statewideSelected && this.business.MembershipType === BusinessBranchMembershipType.Statewide)
    {
      return true
    } else if (this.localSelected && this.business.MembershipType === BusinessBranchMembershipType.B2B)
    {
      return true
    }
    else {
      return false
    }
  }

  get discountPricing (): number {
    if (this.websiteSelected || this.promoPackSelected || this.nationalSelected) {
      return 0
    }
    if (this.business.NonProfitCharity && this.isRenewing)
    {
      return 75
    } else if (this.business.NonProfitCharity)
    {
      return 50
    } else if (this.business.MembershipType === BusinessBranchMembershipType.B2B && this.localSelected)
    {
      return 50
    } else if (this.business.MembershipType === BusinessBranchMembershipType.Statewide && this.statewideSelected)
    {
      return 50
    } else {
      return 0
    }
  }

  get totalPrice (): number {
    let runningTotal = 0
    runningTotal += this.websitePrice
    runningTotal += this.subscriptionPrice
    runningTotal += this.promoPackPrice
    runningTotal += this.regionPricing
    runningTotal += this.allAussieExtrasPrice
    runningTotal += this.expressPricing
    if (this.discountPricing !== 0)
    {
      runningTotal = runningTotal * (this.discountPricing / 100)
    }
    return runningTotal
  }

  onMainStateInput (stateId: string): void {
    this.mainStateId = stateId
  }

  onLocalClick (): void {
    this.localSelected = true
    this.statewideSelected = false
    this.nationalSelected = false
    this.additionalStates = []
    this.additionalZones = []
    this.allAussieExtrasSelected = false
    this.promoPackSelected = false
  }

  onWebsiteSelected (): void {
    if (!(this.statewideSelected || this.nationalSelected))
    {
      this.onStatewideClick()
    }
    this.websiteSelected = true
  }

  onStatewideClick (): void {
    this.localSelected = false
    this.statewideSelected = true
    this.nationalSelected = false
    this.allAussieExtrasSelected = false
  }

  onPromoPackClick (): void {
    this.promoPackSelected = true
    this.onStatewideClick()
  }

  onNationalClick (): void {
    this.localSelected = false
    this.statewideSelected = false
    this.nationalSelected = true
    this.promoPackSelected = false
    this.additionalStates = []
    this.additionalZones = []
    this.allAussieExtrasSelected = true
  }

  onPayNow (): void {
    this.loading = true
    const branchId: string = this.business.BusinessBranchId
    if (!branchId) return
    const request = this.getCheckoutRequest()
    if (!request) return
    console.log('request package: ' + request.SignupPackage.toString())
    Services.API.Payment.CheckoutRequest(branchId, request)
      .then((response) => {
        console.log('response')
        console.info(response)
        window.open(response.SessionUrl, '_self')
      })
      .catch((error) => {
        this.httpError = error
        console.log(error)
        console.error(error)
      })
      .finally(() => {
        this.loading = false
      })
  }

  private payLaterLoading = false
  private payLaterResolved = false
  onPayLaterInvoice (): void {
    this.payLaterLoading = true
    this.payLaterResolved = false
    const branchId: string = this.business.BusinessBranchId
    if (!branchId) return
    const request = this.getCheckoutRequest()
    if (!request) return
    console.log('request package: ' + request.SignupPackage.toString())
    Services.API.Payment.PayLaterRequest(branchId, request)
      .then((response) => {
        console.log(response)
        console.info(response)
        this.payLaterResolved = response
      })
      .catch((errors) => {
        console.error(errors)
      })
      .finally(() => {
        this.payLaterLoading = false
      })
  }

  getCheckoutRequest (): SignupCheckoutRequest | void {
    const request = new SignupCheckoutRequest()
    if (this.expressSelected) {
      request.ExpressSignup = true
    }

    if (this.nationalSelected) {
      request.SignupPackage = SignupPackage.AllAussieExtras
      if (this.websiteSelected) {
        if (!this.websiteDomainValid) return
        request.Domain = this.websiteDomain
        request.WantsWebsite = true
      }
      return request
    }

    if (this.websiteSelected)
    {
      console.log('website package')
      if (!this.websiteDomainValid) return
      request.SignupPackage = SignupPackage.Website
      request.WantsWebsite = true
      request.Domain = this.websiteDomain
      if (this.mainStateId === '') return
      request.MainStateId = this.mainStateId
      // request.AdditionalStateIds = this.additionalStates.map(x => x.Value!.toString())
      if (this.additionalZones.length)
      {
        request.AdditionalZoneIds = this.additionalZones.map(x => x.ZoneId)
      }
      // this.sendCheckoutRequest(request)
      return request
    }

    if (this.statewideSelected)
    {
      if (this.mainStateId === '') return
      request.MainStateId = this.mainStateId
      // request.AdditionalStateIds = this.additionalStates.map(x => x.Value!.toString())
      if (this.promoPackSelected)
      {
        console.log('promo pack package')
        request.SignupPackage = SignupPackage.PromoPack
        if (this.websiteSelected)
        {
          if (!this.websiteDomainValid) return
          request.WantsWebsite = true
          request.Domain = this.websiteDomain
        }
      }
      else {
        console.log('statewide package')
        request.SignupPackage = SignupPackage.Statewide
      }
      if (this.additionalZones.length)
      {
        request.AdditionalZoneIds = this.additionalZones.map(x => x.ZoneId)
      }
      return request
    }

    console.log('normal package')
    request.SignupPackage = SignupPackage.B2b

    if (this.additionalZones.length)
    {
      request.AdditionalZoneIds = this.additionalZones.map(x => x.ZoneId)
    }
    return request
  }
}
